.gallery-section .image {
  background: #c55c21;
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border-radius: 2px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.gallery-section .image img {
  height: 10em;
  border-top: 1px solid #c55c21;
  transition: 0.3s;
}

.gallery-section .image img:hover {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gallery-section .image .title {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
}

.gallery-section .image .title span {
  margin: 0 auto;
  color: white;
}

@media (max-width: 835px) {
  .gallery-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
