.website-section {
  width: 70%;
  margin: 0 auto;
}

.website-section p {
  background: rgb(15, 15, 15);
  color: white;
  padding: 2em;
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}

.website-section p a,
.website-section p a:visited {
  color: #c55c21;
  transition: 0.3s;
  text-decoration: none;
}

.website-section p a:hover {
  text-decoration: underline;
  text-decoration-color: #c55c21;
  color: white;
}
