.about-page {
  text-align: center;
  margin: 0 auto;
}

.menu-section {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title {
  margin: 0 auto;
  font-family: Avenger, Arial, Helvetica, sans-serif;
  /* text-decoration: underline; */
  /* text-underline-position: under;
  text-decoration-color: #c55c21; */
  color: white;
  margin-bottom: 2em;
}
