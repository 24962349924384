.member {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
  background: rgb(15, 15, 15);
  padding: 2em;
  border-bottom: 1px solid #c55c21;
  border-top: 1px solid #c55c21;
  border-radius: 3px;
  /* border: 1px solid #c55c21; */
  margin-bottom: 2em;
}

.member img {
  /* border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21; */
  margin-right: 2em;
  height: 20em;
}

.member .details {
  text-align: left;
}

.member p {
  color: white;
  line-height: 1.6;
}

.member .name {
  font-size: 25px;
  color: #c55c21;
  margin-bottom: 1em;
}

.member .detail-wrapper {
  margin-bottom: 0.6em;
}

.member .detail {
  color: #c55c21;
  font-size: 20px;
}

.member .answer {
  color: white;
}

@media (max-width: 930px) {
  .member {
    display: block;
  }

  .member img {
    margin-bottom: 1em;
  }
}

@media (max-width: 500px) {
  .member {
    width: 90%;
  }
}
