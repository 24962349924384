.news-page {
  width: 70%;
  color: white;
  margin: 0 auto;
}

@media (max-width: 760px) {
  .news-page {
    width: 90%;
  }
}
