.sponsor-page {
  width: 70%;
  margin: 0 auto;
}

.sponsor-page a {
  color: #c55c21;
  text-decoration: none;
}

.sponsor-page a:hover {
  text-decoration: underline;
}

.sponsor-page .page-title {
  margin-bottom: 0.5em;
}

.flags-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lang-icons {
  display: flex;
  justify-content: space-between;
}

.lang-icons img {
  height: 5em;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.lang-icons .switch:hover {
  cursor: pointer;
}

.sponsor-page .left {
  margin-top: 2em;
  font-size: 14px;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sponsor-page .container {
  margin-top: 1em;
  padding: 2em;
  padding-top: 0.5em;
  background: rgb(15, 15, 15);
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}

.sponsor-page .container p {
  margin-top: 1em;
  line-height: 1.6;
  color: white;
}

.sponsor-page .container p span {
  color: #c55c21;
}

.sponsor-page .cards {
  margin-top: 2em;
  color: white;
}

.sponsor-page .card {
  margin-bottom: 2em;
  background: rgb(15, 15, 15);
  padding: 2em;
  padding-top: 0.5em;
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}

.sponsor-page .card p {
  margin-top: 1em;
  line-height: 1.6;
}

.sponsor-page .card p span {
  color: #c55c21;
}
