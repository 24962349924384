.home-robots {
  text-align: center;
  margin-bottom: 2.5em;
}

.home-robots .title {
  text-align: center;
  margin: 0 auto;
}

.home-robot {
  height: 10em;
  transition: 0.3s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.home-robots .robots-slider {
  text-align: center;
  /* background: #4d4b4b; */
  /* background: white; */
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.home-robots .slider-wrapper {
  width: 70%;
  margin: 0 auto;
}

.robots-slider-image-wrapper {
  position: relative;
}

.robots-slider-image-wrapper:hover {
  cursor: pointer;
}

.home-robots .robot-season {
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.robots-slider-image-wrapper:hover .robot-season {
  visibility: visible;
  opacity: 1;
}

.home-robots .robot-season span {
  background: black;
  color: #c55c21;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.robots-slider-image-wrapper:hover .home-robot {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
