.home-info {
  text-align: center;
}

.home-info .container {
  display: flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  width: 70%;
  margin: 0 auto;

  justify-content: space-between;
}

.home-info .container .lni-logo img {
  height: 15em;
  max-width: none !important;
  margin: 0 auto !important;
}

.home-info .container .right .text p {
  font-size: 20px;
  line-height: 1.6;
  color: white;
}

.home-info .container .right {
  margin-left: 0.7em;
  align-self: flex-end;
}

.home-info .container .left {
  margin-right: 0.7em;
}

.home-info .container .right .button-wrapper {
  margin-top: 1em;
}

.home-info .container .right .button-wrapper button {
  color: #c55c21;
  background: transparent;
  border-radius: 2px;
  border: 1px solid #c55c21;
  width: 7em;
  height: 2.4em;
  font-size: 18px;
  transition: 0.3s;
}

.home-info .container .right .button-wrapper button:focus {
  outline: none;
}

.home-info .container .right .button-wrapper button:hover {
  background: #c55c21;
  color: black;
  cursor: pointer;
}

@media (max-width: 1220px) {
  .home-info .container {
    display: block;
  }

  .home-info .container .right .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .home-info .container .right .text {
    margin-top: 1.5em;
  }
}

@media (max-width: 660px) {
  .home-info .container .lni-logo img {
    height: 12em;
  }
}
