.home-banner {
  display: flex;
  display: -moz-box;
  display: -ms-flexbox;
  margin-bottom: 5em;
}

.home-banner .home-main-title {
  font-family: Goldman, Arial, Helvetica, sans-serif;
  font-size: 50px;
  color: #c55c21;
  line-height: normal !important;
}

.home-banner .home-main-title span {
  font-size: 25px;
}

.home-banner-img img {
  height: 15em;
  margin-bottom: 1em;
  display: inline !important;
}
.home-banner .left {
  width: 50%;
}

.home-banner .left .title-wrapper {
  margin-right: 2em;
}

.home-banner .left,
.home-banner .right {
  width: 50%;
  text-align: center;
}

.home-banner .left {
  padding-right: 5em;
}

.home-banner .right .slider img {
  border-radius: 2px;
}

.home-banner .right .slider {
  /* box-shadow: 0 2px 2px 0 #c55c21; */
}

.slider .control-prev.control-arrow:before {
  border-right: 8px solid white;
}

@media (max-width: 1180px) {
  .home-banner .slider {
    margin-top: 1em;
  }
}

@media (max-width: 1060px) {
  .home-banner {
    display: block;
  }

  .home-banner .left {
    padding: 0;
  }

  .home-banner .left,
  .home-banner .right {
    width: 100%;
  }

  .home-banner .title-wrapper {
    padding-right: 0;
  }
}

@media (max-width: 545px) {
  .home-banner .left {
    text-align: center;
  }

  .home-banner .title-wrapper {
    text-align: center;
  }

  .home-banner .home-main-title h1 {
    text-align: left;
  }

  .home-banner .home-main-title br {
    display: block;
  }
}
