.post {
  width: 100%;
  background: rgb(15, 15, 15);
  padding: 2em;
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
  margin-bottom: 2em;
}

.post-title-container {
  margin-bottom: 0.5em;
}

.post-title-container h2 {
  font-size: 28px;
  font-weight: 300;
  /* text-decoration: underline; */
  text-underline-position: under;
  text-decoration-color: #c55c21;
}

.post-date {
  color: #c55c21;
  font-size: 18px;
}

.post-image-container {
  text-align: center;
  margin-top: 3em;

  margin: 0 auto;
}

.post-image-container img {
  height: auto;
  width: 100%;
  margin-top: 2em;
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}

.post-text {
  margin-top: 2em;
}

.post-text p {
  line-height: 1.6;
}
