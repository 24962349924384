@font-face {
  font-family: 'Avenger';
  src: local('Avenger'), url(./Avenger.otf) format('otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* a {
  text-decoration: none;
  color: white;
  transition: 0.3s;
}

a:hover {
  color: #c55c21;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(10, 10, 10); */
  background: black;
  margin-bottom: 0;
  height: 100% !important;
  position: relative;
  min-height: 100vh;
  /* background-color: white; */
  /* padding-bottom: 10em; */
}

.App {
  padding-bottom: 25rem;
}

footer {
  margin-top: 8em;
  position: absolute;
  bottom: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
