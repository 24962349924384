nav {
  /* background: #c55c21; */
  width: 100%;
  background: black;
  padding: 0.5em;
  /* border-bottom: 1px solid #c55c21; */
  display: flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-evenly;
  align-items: center;

  z-index: 100;
  top: 0;
  left: 0;
  /* background: transparent; */
  margin-bottom: 1em;
  height: 4em;
}

nav ul {
  display: flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  /* margin-right: 3em; */
}

.toolbar-logo a {
  display: flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  color: #c55c21;
  text-decoration: none;

}

.toolbar-logo a h3 {
  font-weight: 100;
  margin-left: 1em;
  font-family: Goldman, Arial, Helvetica, sans-serif;

  color: #c55c21;
}

.toolbar-logo a h3 span {
  color: white;
}

.toolbar-logo img {
  height: 3em;
}

.toolbar-navigation-items li a {
  padding: 0 0.5rem;
  text-decoration: none;
}

.toolbar-navigation-items .active a {
  border-bottom: 0.05em solid #c55c21;
}

.toolbar-navigation-items .active a:hover {
  color: white !important;
}

.toolbar-navigation-items li a {
  color: white;
  transition: 0.3s !important;
  font-weight: 350;
  padding-bottom: 5px;
}

.toolbar-navigation-items li a:hover {
  /* color: #c55c21; */
  color: #c55c21;
}

@media (max-width: 768px) {
  .toolbar-navigation-items {
    display: none;
  }
  /* .spacer {
    flex: 1;
  } */
  .toolbar-navigation {
    justify-content: space-between;
  }
  .toolbar-logo {
    margin-left: 50%;
  }
}

@media (min-width: 769px) {
  .toolbar-toggle-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .spacer {
    width: 33%;
  }

  nav {
    justify-content: center;
  }

  .toolbar-logo {
    width: 50%;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  .spacer {
    margin-left: 5em;
  }
}
