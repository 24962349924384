.media-page {
  width: 80%;
  margin: 0 auto;
}

.media-page .main-title {
  justify-content: center;

  margin-bottom: 3em;
}

.media-page .main-title span {
  font-size: 35px;
}

.media-page .title-left {
  justify-content: flex-start;
}

.media-page .title-left span {
  letter-spacing: 15px;
  font-size: 30px;
  padding-left: 15px;
}

.media-page .title-left h2 {
  font-size: 30px;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #c55c21;
}

.media-page .title-left-event h2 {
  font-family: Arial, Helvetica, sans-serif;
}

.media-page .title-left-event span {
  letter-spacing: 0px !important;
}

.media-page .lists-container {
  text-align: left !important;
}
