.menu {
  display: flex;
  text-align: center;
  margin-bottom: 2em;
}

.menu h4 {
  text-align: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 18px;
}

.menu h4:hover {
  color: #c55c21;
  transition: 0.3s;
  cursor: pointer;
}

.menu h4:after {
  content: '|';
  font-family: Arial, Helvetica, sans-serif;
  float: right;
  color: #c55c21;
  margin-right: 1em;
  margin-left: 1em;
}

.menu h4:last-child::after {
  display: none;
}

.menu .no-underline {
  text-decoration: none;
}

.menu .underlined {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #c55c21;
}

.menu .underlined:hover {
  color: white;
}

@media (max-width: 500px) {
  .menu h4:after {
    display: none;
  }

  .menu h4 {
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-size: 15px;
  }
}
