.outreach-section {
  width: 70%;
  margin: 0 auto;
}

.outreach-section .container {
  background: rgb(15, 15, 15);
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}

.outreach-section .container p {
  color: white;
  line-height: 1.6;
  padding: 1.5em;
}

.outreach-section .container .img-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.outreach-section .container .img-container .img-1,
.outreach-section .container .img-container .img-2 {
  width: 50%;
}

.outreach-section .container .img-container img {
  height: auto;
  width: 100%;
  border-bottom: 1px solid #c55c21;
}

@media (max-width: 650px) {
  .outreach-section {
    width: 100%;
  }
}
