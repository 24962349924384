.team-section {
  width: 70%;
  margin: 0 auto;
}

.team-section p {
  line-height: 1.6;
  color: white;
  background: rgb(15, 15, 15);
  padding: 2em;
  border-top: 1px solid #c55c21;
  border-bottom: 1px solid #c55c21;
  border-radius: 3px;
}
