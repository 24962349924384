.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lines-title {
  letter-spacing: 4px;
  font-family: Avenger, Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2em;
  color: white;
}

.lines-title:before,
.lines-title:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #c55c21;
  margin: auto;
}

.lines-title span {
  margin-left: 0.6em;
  margin-right: 0.6em;
}

.page-title {
  font-family: Avenger, Arial, Helvetica, sans-serif;
  color: white;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.page-title h2 {
  text-align: center;
}

.page-title h2 span {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #c55c21;
  line-height: 1.6;
}

@media (max-width: 1000px) {
  .lines-title {
    width: 70%;
  }
}

@media (max-width: 730px) {
  .lines-title {
    width: 80%;
  }
}

@media (max-width: 610px) {
  .lines-title {
    width: 90%;
  }
}

@media (max-width: 540px) {
  .lines-title {
    width: 100%;
    font-size: 12px;
  }
}
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}
