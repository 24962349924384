.robot-page {
  text-align: center;
  margin: 0 auto;
}

.robot-page a {
  color: #c55c21;
  text-decoration: none;
}

.robot-page a:hover {
  text-decoration: underline;
}

.robot-page .header-text {
  text-align: left;
  color: white;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.robot-page .header-text p {
  line-height: 1.6;
}

.robot-page .img-video {
  margin: 0 auto;
  margin-bottom: 5em;
  display: flex;
  width: 90%;
  align-items: flex-start;
  height: auto;
}

.robot-page .img-video .img-container {
  width: 50%;
  margin-right: 1em;
  align-self: center;
}

.robot-page .image-container button {
  background: #c55c21;
  color: white;
  font-weight: bold;
  border: none;
  padding: 0.4em;
  border-radius: 5px;
  cursor: pointer;
}

.robot-page .image-container button:hover {
  color: black;
}

.robot-page .img-video .video-container {
  /* padding-bottom: 56.25%; */
  /* padding-top: 30px; */
  height: 0;
  /* overflow: hidden; */
  width: 50%;
  margin-left: 1em;
}

.robot-page .video-container iframe,
.robot-page .video-container object,
.robot-page .video-container embed {
  width: 100%;
}

.robot-page .img-video img,
.robot-page .img-video iframe {
  border: 2px solid #c55c21;
  border-left: none;
  border-right: none;
}

.robot-page .img-video img {
  width: 100%;
  /* height: auto; */
}

.robot-page .overview-robotpic {
  display: flex;
  margin: 0 auto;
  width: 70%;
  align-items: center;
}

.robot-page .overview-robotpic .overview-container {
  width: 50%;
}

.robot-page .overview-robotpic .image-container {
  width: 50%;
}

.lines-list {
  list-style-position: inside;
  list-style-type: none;
}

.lines-list {
  color: white;
  line-height: 1.6;
}

.lines-list li:before {
  content: '—';
  color: #c55c21;
  margin-left: -20px;
  margin-right: 10px;
}

.robot-page .overview-robotpic .image-container img {
  height: 18em;
  -webkit-filter: drop-shadow(1px 1px 0 #c55c21)
    drop-shadow(-1px -1px 0 #c55c21);
  filter: drop-shadow(1px 1px 0 #c55c21) drop-shadow(-1px -1px 0 #c55c21);
}

.robot-page .competitions {
  margin-top: 2em;
  padding: 1em;
}

@media (max-width: 950px) {
  .robot-page .img-video {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .robot-page .img-video .img-container {
    width: 100%;
    margin-bottom: 1em;
  }

  .robot-page .img-video .video-container {
    width: 100%;
    align-self: flex-end;
    margin-top: 1em;
    height: auto;
  }

  .robot-page .overview-robotpic {
    flex-direction: column;
    justify-content: center;
  }

  .robot-page .overview-robotpic .image-container {
    align-self: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

@media (max-width: 560px) {
  .robot-page .overview-robotpic .image-container img {
    height: 13em;
  }

  .robot-page .overview-robotpic .overview-container {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .robot-page .overview-robotpic .image-container img {
    height: 9em;
  }
}

@media screen and (max-width: 1125px) {
  .robot-page .overview-robotpic .image-container {
    margin-top: 2.5em;
  }
}

@media (max-width: 400px) {
  .robot-page .img-video .video-container {
    width: 100%;
  }
}
