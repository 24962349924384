.side-drawer {
  height: 100%;
  background-color: black;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 250px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  text-align: center;
  padding-bottom: 6em;
  padding-right: 3.7em;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.side-drawer li {
  margin-left: 50%;
  width: 100%;
}

.side-drawer li a,
.side-drawer li {
  color: white !important;
  text-decoration: none !important;
}

.side-drawer li:hover a {
  color: #c55c21 !important;

  /* background: chocolate; */
  border: none;
}

.side-drawer a {
  border-bottom: 1px solid black;
  color: chocolate;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer-logo {
  height: 5em;
  margin-top: 1.5em;
  margin-left: 50%;
  margin-bottom: 2em;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
  .side-drawer-logo {
    display: none;
  }
}
