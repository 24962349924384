footer {
  margin-top: 8em;
  background: rgb(20, 20, 20);
}

footer .container {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding: 12px;
  border-bottom: 1px solid #c55c21;
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-evenly;
}

footer .footer-title h3 {
  font-family: Avenger, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #c55c21;
}

footer .footer-links {
  margin-top: 1em;
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: space-around;
  color: white;
}

footer .footer-links a {
  color: white;
}

footer .footer-links-left {
  text-align: left;
}

footer .footer-links-middle {
  align-items: center;
}

footer .footer-links-right {
  text-align: right;
}

footer .icon {
  transition: 0.3s;
}

footer .icon:hover {
  color: #c55c21 !important;
}

footer a:visited {
  color: white;
}

footer .footer-links a {
  text-decoration: none;
  transition: 0.3s;
  margin: 0.3em 0px 0.3em 0px;
}

footer .footer-links a:hover {
  color: #c55c21;
}

footer .copyright {
  font-size: 12px;
  padding: 1em;
  color: white;
}

footer .copyright a {
  text-decoration: none;
  color: white;
}

footer .copyright a:hover {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #c55c21;
}

@media (max-width: 560px) {
  footer .section {
    margin-left: 1em;
    margin-right: 1em;
  }
}
